import { ReactElement, ReactNode, useMemo } from 'react';
import type { Translate } from '@cms/i18n';
import Image from 'next/image';

import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';
import { PlatformID, Tag } from '@common/clients/api/types.gen';
import { BackgroundWidth, ThumbnailWidth } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';
import { HeadingTag } from '@web/atoms';
import { Winner } from '@web/atoms/racing/types';
import { SponsoredPartnerLogo } from '@web/atoms/SponsoredPartnerLogo';
import { DetailsBlock } from '@web/molecules/DetailsBlock';
import { Columns, Row } from '@web/molecules/DetailsBlock/types';
import { InnerTag } from '@web/molecules/TagIntroBlock/atoms';
import { Title } from '@web/molecules/TagIntroBlock/atoms/Title';
import { TagBody } from '@web/molecules/TagIntroBlock/TagBody';
import { TrackImage } from '@web/molecules/TagIntroBlock/TrackImage';
import { replaceNewLine } from '@web/utils/replaceNewLine';

import { Schedule } from '../GrandPrixSchedule';
import * as coureurPlaceholder from './gp-coureur-placeholder.png';
import { Thumbnail } from './Thumbnail';
import { TopHalf } from './TopHalf';

import styles from './TagIntroBlock.module.scss';

export interface TagDataType {
    [key: string]: string | ReactNode | Schedule[] | Winner[];
}

export enum TagIntroBlockType {
    AUTHOR = 'author',
    COMMON = 'common',
    DRIVER = 'driver',
    GRAND_PRIX = 'grand-prix',
    TEAM = 'team',
}

export interface Props {
    type: TagIntroBlockType;
    title: string | ReactElement;
    titleHtmlTag?: HeadingTag;
    superTitle?: string;
    tag?: Tag;
    description?: string;
    thumbnail?: string;
    backgroundImage?: string;
    columns?: Columns;
    data?: TagDataType;
    isMiniView?: boolean;
    hasTagOutro?: boolean;
    linkTagSlug?: string;
    isListMember?: boolean;
    hasNoSpaceBelow?: boolean;
    __translate?: Translate;
}

export const TagIntroBlock = (props: Props) => {
    const { platform } = useContextData();
    const contextData = useContextData();

    const hasTagBody = (props: Props): boolean => Boolean(props.columns || props.description);
    const hasAdsAndPartner = Boolean(contextData.allowAds && props.tag?.partner);

    const getAlternativeColor = (props: Props): string => {
        if (props.data?.isPastRace) return '#414448';
        if (props.data?.teamColor) return props.data?.teamColor as string;
        return '';
    };

    const classes = ['inverted', 'tagIntroBlock', styles.TagIntroBlock, styles[`${props.type}-type`]];
    if (props.isMiniView) classes.push(styles['is-mini-view']);
    if (!props.hasTagOutro) classes.push(styles['no-tag-outro']);
    if (props.isListMember) classes.push(styles['is-in-list']);
    if (props.hasNoSpaceBelow) classes.push(styles['no-space-below']);

    const alternativeColor = getAlternativeColor(props);
    const imageContains = /webp\./;
    const thumbnailCheck = imageContains.test(props.thumbnail || '');
    let imageThumb = props.thumbnail;
    if (thumbnailCheck)
        imageThumb = props.thumbnail
            ? props.thumbnail.replace('{WebpWidth}', `${ThumbnailWidth.WIDTH_225}`)
            : '';
    if (props.tag?.thumbnail) props.tag.thumbnail = imageThumb;

    // show placeholder when no image thumb is available
    if (!imageThumb && props.type === 'driver') imageThumb = coureurPlaceholder.default.src;

    const backgroundCheck = imageContains.test(props.backgroundImage || '');
    let imageBackground = props.backgroundImage;
    if (backgroundCheck)
        imageBackground = props.backgroundImage
            ? props.backgroundImage.replace('{WebpWidth}', `${BackgroundWidth.WIDTH_1200}`)
            : '';

    const {
        borderTopColor,
        borderBottomColor,
        backgroundColor,
        hasTagOutro,
        isCommonType,
        isGrandPrixType,
        teamName,
        teamNameSlug,
        authorFunction,
        authorSlug,
        titleHasSpaces,
        titleIsString,
    } = useMemo(
        () => ({
            backgroundColor: alternativeColor ? (alternativeColor as string) : '',
            borderBottomColor: alternativeColor ? (alternativeColor as string) : '',
            borderTopColor: alternativeColor ? (alternativeColor as string) : '',
            hasTagOutro: props?.hasTagOutro,
            isCommonType: props?.type === TagIntroBlockType.COMMON,
            isAuthorType: props?.type === TagIntroBlockType.AUTHOR,
            isDriverType: props?.type === TagIntroBlockType.DRIVER,
            isGrandPrixType: props?.type === TagIntroBlockType.GRAND_PRIX,
            isTeamType: props?.type === TagIntroBlockType.TEAM,
            teamName: props?.data?.teamName?.toString(),
            teamNameSlug: props?.data?.teamNameSlug?.toString(),
            authorFunction: props?.data?.function?.toString(),
            authorSlug: props?.data?.authorSlug?.toString(),
            titleHasSpaces: /\s/.test(props.title as string),
            titleIsString: typeof props?.title === 'string',
        }),
        [props, alternativeColor],
    );
    const titleString = useMemo(() => props?.title as string, [props.title]);
    const __meta = useTranslation('meta').t;

    const readAllButton = (
        <button className={styles['read-more']}>
            {__meta`read-all`} {<Icon.arrowsRight />}
        </button>
    );

    const headerTitles = [
        <InnerTag key="inner-tag" backgroundColor={backgroundColor} tag={props.superTitle} />,
        <Title
            key="heading-title"
            borderBottomColor={borderBottomColor}
            titleHtmlTag={props?.titleHtmlTag}
            title={props?.title || ''}
            titleHasSpaces={titleHasSpaces}
            titleIsString={titleIsString}
            titleString={titleString}
        />,
    ];

    // reverse the order of titles for vi
    if (platform.id === PlatformID.VI) {
        headerTitles.reverse();
    }

    return (
        <div className={classes.join(' ')} style={{ borderTopColor }}>
            {hasAdsAndPartner && (
                <>
                    <div className={styles['partner']}>
                        <SponsoredPartnerLogo hasLink tag={props.tag} />
                    </div>
                </>
            )}
            {imageBackground ? (
                <Image
                    alt="tag-background-image"
                    className={styles['background-image']}
                    width="1560"
                    height="344"
                    src={imageBackground}
                    loader={({ src }) => src}
                />
            ) : null}
            <header>
                <>
                    <div className={styles['header-container']}>
                        <TopHalf linkTagSlug={props?.linkTagSlug} type={props.type} tag={props.tag}>
                            {headerTitles.map((element) => element)}
                            {isGrandPrixType ? (
                                <TrackImage
                                    imageThumb={imageThumb || ''}
                                    title={__meta(`grandprix.name`, {
                                        gpName: props?.title || '',
                                    })}
                                />
                            ) : null}
                            {!isGrandPrixType && imageThumb ? (
                                <Thumbnail
                                    tag={props.tag}
                                    type={props.type}
                                    imageThumb={imageThumb || ''}
                                    teamName={teamName ? teamName : authorFunction ? authorFunction : ''}
                                    teamNameSlug={teamNameSlug ? teamNameSlug : authorSlug ? authorSlug : ''}
                                />
                            ) : null}
                        </TopHalf>

                        <TagBody
                            props={props}
                            type={props.type}
                            hasTagBody={hasTagBody(props)}
                            isMiniView
                            data={props.data}
                            description={props.description}
                            readAllButton={readAllButton}
                        />
                    </div>
                    {Boolean(!isGrandPrixType && imageThumb) && (
                        <Thumbnail
                            tag={props.tag}
                            type={props.type}
                            imageThumb={imageThumb || ''}
                            teamName={teamName ? teamName : authorFunction ? authorFunction : ''}
                            teamNameSlug={teamNameSlug ? teamNameSlug : authorSlug ? authorSlug : ''}
                        />
                    )}
                </>
            </header>

            {Boolean(props?.description || props?.columns) ? (
                <div className={styles['tag-body']}>
                    {Boolean(isCommonType && props.columns) ? <h3>info</h3> : null}
                    {props.columns ? (
                        <DetailsBlock columns={props.columns} data={(props.data as Row) || {}} />
                    ) : null}
                    {props?.description ? (
                        <div className={styles.description}>
                            {Boolean(props.description) ? (
                                <div
                                    dangerouslySetInnerHTML={{ __html: replaceNewLine(props.description) }}
                                />
                            ) : null}
                        </div>
                    ) : null}
                </div>
            ) : null}

            {hasTagOutro ? (
                <button
                    className={styles['scroll-to-tag-outro']}
                    onClick={() => {
                        const outro = document.getElementById('tag-outro');
                        const position = outro?.getBoundingClientRect();
                        if (position) {
                            window.scrollTo({
                                top: position.top + window.scrollY - window.innerHeight * 0.05,
                                behavior: 'smooth',
                            });
                        }
                    }}
                >
                    <Icon.arrowsDown />
                </button>
            ) : null}
        </div>
    );
};
