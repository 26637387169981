import { ReactElement } from 'react';

import { Heading, HeadingTag } from '@web/atoms';

interface Props {
    borderBottomColor: string;
    titleHtmlTag?: HeadingTag;
    title: string | ReactElement;
    titleHasSpaces?: boolean;
    titleIsString?: boolean;
    titleString: string;
}

export const Title = ({
    borderBottomColor,
    title,
    titleHasSpaces,
    titleHtmlTag,
    titleIsString,
    titleString,
}: Props) => (
    <Heading as={titleHtmlTag || 'h1'} style={{ borderBottom: borderBottomColor }}>
        {titleHasSpaces && titleIsString ? (
            <>
                <i>{titleString.split(' ')[0]}</i>
                {titleString.substring(titleString.indexOf(' '))}
            </>
        ) : (
            title
        )}
    </Heading>
);
