import { ReactNode } from 'react';

import { Props as TagIntroBlockProps } from './TagIntroBlock';
import { TagLinkWrapper } from './TagLinkWrapper';

import styles from './TagIntroBlock.module.scss';

interface Props extends Pick<TagIntroBlockProps, 'tag' | 'type' | 'linkTagSlug'> {
    teamName?: string;
    teamNameSlug?: string;
    titleHasSpaces?: boolean;
    titleIsString?: boolean;
    children?: ReactNode;
}

export const TopHalf = (props: Props) => {
    const { type, tag, linkTagSlug } = props;
    return (
        <div className={styles['header-tophalf']}>
            <TagLinkWrapper {...{ type, tag, linkTagSlug }}>{props.children}</TagLinkWrapper>
        </div>
    );
};
