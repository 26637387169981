import Image from 'next/image';

import { Tag } from '@common/clients/api';
import { TagImage } from '@web/atoms/TagImage';

import { TagIntroBlockType } from './TagIntroBlock';
import { TagLinkWrapper } from './TagLinkWrapper';

import styles from './TagIntroBlock.module.scss';

interface DriverImageProps {
    type: TagIntroBlockType;
    imageThumb: string;
    tag?: Tag;
    teamName?: string;
    teamNameSlug?: string;
}

export const Thumbnail = ({ teamName, teamNameSlug, tag, type, imageThumb }: DriverImageProps) => (
    <div className={styles['thumbnail-container']}>
        <TagLinkWrapper {...tag} type={type}>
            {tag && type !== TagIntroBlockType.TEAM ? (
                <TagImage tag={tag} />
            ) : (
                <Image
                    alt={teamName || ''}
                    width="260"
                    height="260"
                    src={imageThumb}
                    loader={({ src }) => src}
                />
            )}
        </TagLinkWrapper>
        {teamName &&
            ((teamNameSlug as string) ? (
                <TagLinkWrapper {...tag} type={TagIntroBlockType.TEAM} linkTagSlug={teamNameSlug as string}>
                    <span className={styles['team-name-tag']}>{teamName}</span>
                </TagLinkWrapper>
            ) : (
                <span className={styles['team-name-tag']}>{teamName}</span>
            ))}
    </div>
);
