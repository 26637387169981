import { ReactElement } from 'react';

import { Winner } from '@web/atoms/racing/types';
import { AuthorInfo } from '@web/molecules/AuthorInfo';

import { GrandPrixSchedule, Schedule } from '../GrandPrixSchedule';
import { DriverInfo } from './DriverInfo';
import { Props as TagIntroBlockProps, TagIntroBlockType } from './TagIntroBlock';
import { TagLinkWrapper } from './TagLinkWrapper';
import { TeamsInfo } from './TeamsInfo';

import styles from './TagIntroBlock.module.scss';

interface TagBodyProps {
    type: TagIntroBlockType;
    hasTagBody: boolean;
    isMiniView: boolean;
    props: TagIntroBlockProps;
    data: TagIntroBlockProps['data'];
    description?: string;
    readAllButton?: ReactElement;
}

export const TagBody = (_props: TagBodyProps) => {
    const { data, type, hasTagBody, isMiniView, props, description, readAllButton } = _props;
    switch (type) {
        case TagIntroBlockType.COMMON:
            return (
                <>
                    <hr />
                    <TagLinkWrapper {...props}>
                        {description ? (
                            <div
                                className={styles.description}
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        ) : (
                            <></>
                        )}
                        {readAllButton}
                    </TagLinkWrapper>
                </>
            );
        case TagIntroBlockType.AUTHOR:
            return (
                <>
                    <hr />
                    {!isMiniView && hasTagBody ? <hr /> : null}
                    <AuthorInfo {...data} />
                </>
            );
        case TagIntroBlockType.DRIVER:
            return (
                <>
                    <hr />
                    <DriverInfo {...props} />
                    {!isMiniView && hasTagBody ? <hr /> : null}
                </>
            );
        case TagIntroBlockType.TEAM:
            return (
                <>
                    <hr />
                    <TeamsInfo {...props} />
                    {!isMiniView && hasTagBody ? <hr /> : null}
                </>
            );
        case TagIntroBlockType.GRAND_PRIX:
            return (
                <>
                    <hr />
                    <GrandPrixSchedule
                        isPastRace={Boolean(data?.isPastRace)}
                        schedule={data?.schedule as Schedule[]}
                        grandPrixInfo={data?.grandPrixInfo as string | ReactElement}
                        grandPrixWinners={data?.grandPrixWinners as Winner[]}
                        grandPrixSlug={props?.linkTagSlug}
                        isMiniView={props.isMiniView}
                    />
                    {!isMiniView && hasTagBody ? <hr /> : null}
                </>
            );
        default:
            return null;
    }
};
