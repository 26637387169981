import Image from 'next/image';

import { useTranslation } from '@cms/i18n/client';
import { Tag } from '@common/clients/api';
import { OptionalLink } from '@web/atoms/OptionalLink';

import styles from './SponsoredPartnerLogo.module.scss';

export const SponsoredPartnerLogo = ({
    tag,
    isLight,
    hasBackgroundImage,
    hasDarkBackground,
    hasLink,
}: {
    tag?: Tag;
    isLight?: boolean;
    hasBackgroundImage?: boolean;
    hasDarkBackground?: boolean;
    hasLink?: boolean;
}) => {
    const __livescores = useTranslation('livescores').t;

    if (!tag?.partner) return null;

    const backgroundStyle =
        hasBackgroundImage && tag.background
            ? {
                  backgroundImage: `url(${tag.background})`,
              }
            : {};

    return (
        <div
            className={`${styles.partner} ${hasDarkBackground ? styles['has-dark-background'] : ''}`}
            style={backgroundStyle}
        >
            <span>{__livescores`match.officialpartner`}</span>
            <OptionalLink
                href={hasLink ? tag.partner?.url : undefined}
                isSponsored
                className={styles['partner-link']}
            >
                <Image
                    alt={tag.partner?.name}
                    src={isLight ? tag.partner?.darkBackgroundIcon : tag.partner?.lightBackgroundIcon}
                    width={120}
                    height={22}
                    unoptimized={true}
                    loader={({ src }) => src}
                    className={styles['partner-logo']}
                />
            </OptionalLink>
        </div>
    );
};
