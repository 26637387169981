import { ReactNode } from 'react';

import { InternalLink, Route } from '@web/atoms/InternalLink';
import { RouteByTagType } from '@web/routing';

import { TagIntroBlockProps } from '.';

export interface Props extends Pick<TagIntroBlockProps, 'type' | 'linkTagSlug' | 'tag'> {
    children?: ReactNode;
}

export const TagLinkWrapper = ({ type, children, linkTagSlug, tag }: Props) => {
    if (tag?.tagType && linkTagSlug) {
        return (
            <InternalLink route={RouteByTagType[tag.tagType]} query={{ tag: linkTagSlug }}>
                {children}
            </InternalLink>
        );
    } else if (linkTagSlug) {
        let route: Route = Route.Driver;
        switch (type) {
            case 'grand-prix':
                route = Route.Grandsprix;
                break;
            case 'team':
                route = Route.Team;
                break;
        }
        return (
            <InternalLink route={route} query={{ tag: linkTagSlug }}>
                {children}
            </InternalLink>
        );
    } else {
        return <>{children}</>;
    }
};
